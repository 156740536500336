import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Coffee from "./coffee.svg"
import Beer from "./beer.svg"
import Cutlery from "./cutlery.svg"
import Megaphone from "./megaphone.svg"
import Tieckt from "./ticket.svg"
// ______________________________________________________
//
export type IconName = "coffee" | "beer" | "cutlery" | "megaphone" | "ticket"
type Props = {
  className?: string
  iconName: IconName
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => {
  switch (props.iconName) {
    case "coffee":
      return (
        <p className={props.className}>
          <Coffee />
        </p>
      )
    case "beer":
      return (
        <p className={props.className}>
          <Beer />
        </p>
      )
    case "cutlery":
      return (
        <p className={props.className}>
          <Cutlery />
        </p>
      )
    case "megaphone":
      return (
        <p className={props.className}>
          <Megaphone />
        </p>
      )
    case "ticket":
      return (
        <p className={props.className}>
          <Tieckt />
        </p>
      )
  }
}
// ______________________________________________________
//
const StyledComponent = styled(Component)`
  display: flex;
  justify-content: center;
  align-items: center;
`
// ______________________________________________________
//
export default StyledComponent
