import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Content from "./content"
import { UseToggleLanguage } from "@components/hooks"
import ImgBgPattern from "@/src/images/2020/hero_patterns.png"
import ImgBgPatternSP from "@/src/images/2020/hero_patterns_sp.png"

// ______________________________________________________
//
type Props = {
  className?: string
} & UseToggleLanguage
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <div className="Inner">
      <Content />
    </div>
    <div className="Gradient" />
  </div>
)
// ______________________________________________________
//
const spStyle = `
  .Inner {
    height: 524px;
    padding-top: 104px;
    background: url(${ImgBgPatternSP}) repeat-x center;
    background-size: auto 100%;
  }
  .Gradient {
    height: 12px;
  }
`
const StyledComponent = styled(Component)`
  background: linear-gradient(180deg, #1c1a19 0%, #282c34 100%);
  .Inner {
    height: 940px;
    padding-top: 168px;
    background: url(${ImgBgPattern}) repeat-x center;
    background-size: 1680px auto;
    box-sizing: border-box;
  }
  .Gradient {
    height: 16px;
    background: linear-gradient(
      180deg,
      #282c34 0%,
      #d33736 46.35%,
      #dd6041 60.94%,
      #ffe564 100%
    );
  }
  ${media.lessThan("medium")`${spStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
