import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

// ______________________________________________________
//
type Props = {
  className?: string
  title: string
  color?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <h2 className={props.className}>{props.title}</h2>
)
// ______________________________________________________
//
const spStyle = `
  margin-bottom: 22px;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
`
const StyledComponent = styled(Component)`
  margin-bottom: 48px;
  color: ${props => props.color || props.theme.colors.black};
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 72px;
  line-height: 1;
  text-align: center;
  ${media.lessThan("medium")`${spStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
