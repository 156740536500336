import React, { useCallback } from "react"
import { PagesTopQuery } from "@/types"
import Hero from "./hero"
import Speakers from "./speakers"
import TimeTable from "./time-table"
import Venue from "./venue"
import Sponsors from "./sponsors"
import Faq from "./faq"
import Team from "./team"
import Header from "@components/common/header"
import Footer from "@components/common/footer"
import { sortByOrder, sortByCreatedAt, filterByLang } from "@components/utils"
import { UseToggleLanguage } from "@components/hooks"
// ______________________________________________________
//
type Props = PagesTopQuery &
  UseToggleLanguage & {
    className?: string
    path: string
  }
// ______________________________________________________
//
const Component: React.FC<Props> = props => {
  const toggleLanguage = useCallback(() => {
    if (props.lang === "ja") {
      props.toggleToEN()
    } else if (props.lang === "en-US") {
      props.toggleToJA()
    }
  }, [props.lang])
  return (
    <div className={props.className}>
      <Header
        toggleLanguage={toggleLanguage}
        lang={props.lang}
        path={props.path}
      />
      <Hero
        lang={props.lang}
        toggleToEN={props.toggleToEN}
        toggleToJA={props.toggleToJA}
      />

      <TimeTable
        sessions={props.allContentfulSession.edges.filter(
          filterByLang(props.lang)
        )}
        programs={props.allContentfulProgram.edges.filter(
          filterByLang(props.lang)
        )}
      />
      <Speakers
        speakers={props.allContentfulSpeaker.edges
          .filter(filterByLang(props.lang))
          .sort(sortByOrder)}
      />
      <Venue
        paragraph={
          props.allContentfulParagraph.edges
            .filter(filterByLang(props.lang))
            .filter(edge => edge.node.title === "Venue paragraph")[0]
        }
      />
      <Faq />
      {/* <Sponsors
        lists={[
          {
            title: "SPECIAL",
            plan: "special",
            sponsors: props.allContentfulSponsor.edges
              .filter(filterByLang(props.lang))
              .filter(edge => edge.node.plan === "special"),
          },
          {
            title: "PLATINUM",
            plan: "platinum",
            sponsors: props.allContentfulSponsor.edges
              .filter(filterByLang(props.lang))
              .filter(edge => edge.node.plan === "platinum")
              .sort(sortByCreatedAt),
          },
          {
            title: "GOLD",
            plan: "gold",
            sponsors: props.allContentfulSponsor.edges
              .filter(filterByLang(props.lang))
              .filter(edge => edge.node.plan === "gold")
              .sort(sortByCreatedAt),
          },
          {
            title: "SILVER",
            plan: "silver",
            sponsors: props.allContentfulSponsor.edges
              .filter(filterByLang(props.lang))
              .filter(edge => edge.node.plan === "silver")
              .sort(sortByCreatedAt),
          },
        ]}
      /> */}
      {/* <Team /> */}
      <Footer
        lists={
          props.lang === "ja"
            ? (props.site?.siteMetadata?.footerLinkListsJA as any)
            : (props.site?.siteMetadata?.footerLinkListsEN as any)
        }
      />
    </div>
  )
}
// ______________________________________________________
//
export default Component
