import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import media from "styled-media-query"
import { PagesTopQuery } from "@/types"
import Arrow from "@/src/images/icon/ic-arrow_right.svg"
// ______________________________________________________
//
export type Session = PagesTopQuery["allContentfulSession"]["edges"][0]
type ContainerProps = {
  className?: string
  session: Session
}
type Props = {
  className?: string
  linkTo: string
  sessionStartAt: string
  imageTags: React.ReactNode[]
  sessionName: string
  speakerName: string
  spokenLanguage: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => {
  const {
    sessionStartAt,
    sessionName,
    speakerName,
    imageTags,
    spokenLanguage,
  } = props

  return (
    <li className={props.className}>
      <Link to={props.linkTo}>
        <div className="TimeBlock">
          <p className="Time">{sessionStartAt}</p>
          <span className="TimeMinute">30min</span>
        </div>
        <div className="Detail">
          <figure className="Icon">
            {imageTags.map(image => {
              return image
            })}
          </figure>
          <div className="DetailInner">
            <h3 className="Title">{sessionName}</h3>
            <div className="NameContiner">
              <p className="Name">{speakerName}</p>
              <span className="Language">{spokenLanguage}</span>
            </div>

            <span className="Arrow">
              <Arrow />
            </span>
          </div>
        </div>
      </Link>
    </li>
  )
}
// ______________________________________________________
//
const spStyle = `
  a {
    position: relative;
    &:hover {
      opacity: 1;
      .Arrow {
        transform: translate(0, -50%);
      }
    }
  }
  .TimeBlock {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 103px;
    padding: 8px 12px;
    border-radius: 0px 0px 16px 0px;
    background: rgba(216, 216, 216, 0.8);
    box-sizing: border-box;
  }
  .Time {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1;
  }
  .TimeMinute {
    padding-left: 3px;
    font-size: 12px;
    line-height: 1;
  }
  .Detail {
    height: auto;
    padding: 40px 32px 23px 20px;
    border-top: 1px solid rgba(216, 216, 216, 0.8);
  }
  .Icon {
    margin-right: 16px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .DetailInner {
    padding: 0;
    border-bottom: 0;
  }
  .Title {
    font-size: 16px;
    line-height: 1.2;
  }
  .NameContiner {
    margin-top: 2px;
  }
  .Name {
    font-size: 12px;
  }
  .Arrow {
    right: 4px;
    width: 20px;
    height: 20px;
  }
`
const StyledComponent = styled(Component)`
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: opacity 0.3s ease-out;
    &:hover {
      opacity: 0.7;
      .Arrow {
        transform: translate(8px, -50%);
      }
    }
  }
  .TimeBlock {
    padding: 0 22px;
    text-align: center;
  }
  .Time {
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.black};
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
    text-align: center;
  }
  .TimeMinute {
    color: ${({ theme }) => theme.colors.black};
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 1;
    opacity: 0.72;
  }
  .Detail {
    display: flex;
    align-items: center;
    flex: 1;
    height: 108px;
  }
  .Icon {
    width: 72px;
    height: 72px;
    margin-right: 24px;
    overflow: hidden;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .DetailInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 16px 0 20px;
    flex: 1;
    border-bottom: 1px solid rgba(216, 216, 216, 0.8);
    box-sizing: border-box;
  }
  .Title {
    color: ${({ theme }) => theme.colors.black};
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
  }
  .NameContiner {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.black};
  }
  .Name {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 1;
  }
  .Language {
    margin-left: 8px;
    padding: 4px 8px;
    border: 1px solid #6d6d6d;
    border-radius: 4px;
    box-sizing: border-box;
    color: #6d6d6d;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 10px;
    line-height: 1;
  }
  .Arrow {
    position: absolute;
    top: 50%;
    right: 24px;
    width: 32px;
    height: 32px;
    transition: transform 0.3s ease-out;
    transform: translateY(-50%);
    svg {
      width: 100%;
      height: auto;
      opacity: 0.4;
    }
  }
  ${media.lessThan("medium")`${spStyle}`}
`
// ______________________________________________________
//
const Container: React.FC<ContainerProps> = props => {
  if (!props.session.node.speakers) return <></>
  const linkTo = `/2020/sessions/${props.session.node.slug}`
  const sessionStartAt = props.session.node.startsAt
  const imageTags = props.session.node.speakers.map((speaker, i) => (
    <img
      key={i}
      className="avatar"
      // @ts-ignore
      loading="lazy"
      src={speaker?.avatar?.file?.url ?? ""}
    />
  ))
  const sessionName = props.session.node.name ?? ""
  const speakerName =
    props.session.node.speakers.length === 1
      ? props.session.node.speakers[0]?.name ?? ""
      : props.session.node.speakers.length > 1
      ? props.session.node.speakers
          .map(speaker => speaker?.name ?? "")
          .reduce((a, b) => `${a} & ${b}`)
      : ""
  const spokenLanguage = props.session.node.spokenLanguage ?? ""
  return (
    <StyledComponent
      className={props.className}
      linkTo={linkTo}
      sessionStartAt={sessionStartAt}
      imageTags={imageTags}
      sessionName={sessionName}
      speakerName={speakerName}
      spokenLanguage={spokenLanguage}
    />
  )
}
// ______________________________________________________
//
export default Container
