import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { Link } from "gatsby"
import Arrow from "@/src/images/icon/ic-arrow_right.svg"
// ______________________________________________________
//
type Props = {
  className?: string
  label: string
  href: string
  target?: boolean
  type?: "external" | "back" | "internal"
  disabled: boolean
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => {
  if (props.disabled) {
    return (
      <button className={`${props.type} ${props.className}`} disabled>
        {props.label}
      </button>
    )
  }
  if (props.target) {
    return (
      <a
        className={`${props.type} ${props.className}`}
        href={props.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.label}
        <span className="Icon">
          <Arrow />
        </span>
      </a>
    )
  }
  return (
    <Link className={`${props.type} ${props.className}`} to={props.href}>
      {props.label}
      <span className="Icon">
        <Arrow />
      </span>
    </Link>
  )
}
// ______________________________________________________
//
const spStyle = `
  font-size: 14px;
  padding: 16px 24px;
  &:hover {
    opacity: 1;
    &.external {
      .Icon {
        transform: translate(0, -50%);
      }
    }
    &.back {
      .Icon {
        transform: translate(0, -50%);
      }
    }
  }
`

const StyledComponent = styled(Component)`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 16px 24px;
  background: ${({ theme }) => theme.colors.ivory};
  border-radius: 32px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  text-decoration: none;
  transition: opacity 0.3s ease-out;

  .Icon {
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    transition: transform 0.3s ease-out;
  }
  &.disabled {
    opacity: 0.32;
    pointer-events: none;
  }
  &.external {
    padding-right: 54px;
    .Icon {
      right: 24px;
    }
  }
  &.back {
    padding-left: 54px;
    .Icon {
      left: 24px;
      transform: translateY(-50%) rotate(180deg);
    }
  }
  &.internal {
    padding-left: 54px;
    .Icon {
      left: 24px;
      transform: translateY(-50%) rotate(45deg);
    }
  }

  &:hover {
    opacity: 0.7;
    &.external {
      .Icon {
        transform: translate(8px, -50%);
      }
    }
    &.back {
      .Icon {
        transform: translate(-8px, -50%) rotate(180deg);
      }
    }
  }
  ${media.lessThan("medium")`${spStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
