export function getFormattedDateFromString(dateString: string) {
  const date = new Date(dateString)
  return getFormattedDateLabel(date)
}
export function getFormattedDateLabel(date: Date) {
  const h = date.getHours()
  const m = date.getMinutes()
  const fillZero = (n: number) => `00${n}`.slice(-2)
  return `${fillZero(h)}:${fillZero(m)}`
}
