type EdgeOrder = {
  node: { order?: number | null | undefined }
}
type EdgeCreatedAt = {
  node: { createdAt?: string | null | undefined }
}
type EdgeLocal = {
  node: { node_locale?: string | null | undefined }
}
// ______________________________________________________
//
export function sortByOrder(a: EdgeOrder, b: EdgeOrder) {
  return (a.node.order || 0) - (b.node.order || 0)
}
export function sortByCreatedAt(a: EdgeCreatedAt, b: EdgeCreatedAt) {
  if (!a.node.createdAt || !b.node.createdAt) return 0
  return (
    new Date(a.node.createdAt).getTime() - new Date(b.node.createdAt).getTime()
  )
}
export function filterByLang(lang: string | null) {
  return (edge: EdgeLocal) => {
    return edge.node.node_locale === lang
  }
}
