import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import SectionTitle from "@components/common/sectionTitle"
import List from "./list"
import { Session } from "./list/sessionItem"
import { Program } from "./list/programItem"
// ______________________________________________________
//
type Props = {
  className?: string
  sessions: Session[]
  programs: Program[]
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <section className={props.className} id="schedule">
    <div className="Inner">
      <SectionTitle title="Schedule" />
      <List sessions={props.sessions} programs={props.programs} />
    </div>
  </section>
)
// ______________________________________________________
//
const spStyle = `
  .Inner {
    padding: 48px 0;
  }
`
const StyledComponent = styled(Component)`
  background-color: ${({ theme }) => theme.colors.ivory};
  .Inner {
    max-width: ${1024 + 80}px;
    margin: 0 auto;
    padding: 104px 40px 112px;
    box-sizing: border-box;
  }
  ${media.lessThan("medium")`${spStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
