import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Button from "@components/common/button"
import ImgHero from "@/src/images/2020/img_logo_vertical.svg"

// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <h1 className="Logo">
      <ImgHero />
    </h1>
    <p className="Lede">
      2020/02/17
      <br />
      <a
        href="/2020/entries/cancellation-announcement"
        rel="noopener noreferrer"
      >
        React Conf Japan 開催中止のお知らせ
      </a>
    </p>
  </div>
)
// ______________________________________________________
//
const spStyle = `
  .Logo {
    max-width: 246px;
    margin-bottom: 32px;
  }
  .Lede {
    padding: 0 32px;
    font-size: 18px;
    line-height: 1.7;
    br {
      display: none;
    }
  }
`
const StyledComponent = styled(Component)`
  .Logo {
    max-width: 565px;
    margin: 0 auto 56px;
    svg {
      width: 100%;
      height: auto;
    }
  }
  .Lede {
    color: ${({ theme }) => theme.colors.ivory};
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    box-sizing: border-box;
    a {
      margin-left: 12px;
      color: ${({ theme }) => theme.colors.ivory};
      text-decoration: underline;
    }
  }
  ${media.lessThan("medium")`${spStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
