import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import SpeakerItem, { Speaker } from "./item"
// ______________________________________________________
//
export type Props = {
  className?: string
  speakers: Speaker[]
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <ul className={props.className}>
    {props.speakers.map((speaker, index) => {
      if (speaker.node.name === "Anonymous") return null
      return (
        <li key={index} className="ListItem">
          <SpeakerItem {...speaker} />
        </li>
      )
    })}
  </ul>
)
// ______________________________________________________
//
const spStyle = `
  > li {
    width: 50%;
  }
`
const StyledComponent = styled(Component)`
  display: flex;
  flex-wrap: wrap;
  > li {
    width: 25%;
  }
  ${media.lessThan("medium")`${spStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
