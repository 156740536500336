import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { getFormattedDateLabel } from "@components/utils"
import SessionItem, { Session } from "./sessionItem"
import SessionItemNoLink from "./sessionItem/noLink"
import ProgramItem, { Program } from "./programItem"
// ______________________________________________________
//
type Props = {
  className?: string
  items: (Session | Program)[]
}
type ContainerProps = {
  className?: string
  sessions: Session[]
  programs: Program[]
}
// ______________________________________________________
//
function procNodeTimes(node: { startsAt: string; endsAt: string }) {
  const startsAt = new Date(node.startsAt)
  const endsAt = new Date(node.endsAt)
  const durationMinutes = (endsAt.getTime() - startsAt.getTime()) / 1000 / 60
  return {
    startsTime: startsAt.getTime(),
    startsAt: getFormattedDateLabel(startsAt),
    endsAt: getFormattedDateLabel(endsAt),
    durationMinutes,
  }
}
function isSession(item: Session | Program): item is Session {
  return "speakers" in item.node
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <ul className={props.className}>
    {props.items
      .map(({ node }) => ({
        node: {
          ...node,
          ...procNodeTimes({
            startsAt: node.startsAt,
            endsAt: node.endsAt,
          }),
        },
      }))
      .sort((a, b) => a.node.startsTime - b.node.startsTime)
      .map((item, i) => {
        if (isSession(item)) {
          if (item.node.publishDetailPage) {
            return <SessionItem key={i} session={item} className="ListItem" />
          }
          return (
            <SessionItemNoLink key={i} session={item} className="ListItem" />
          )
        }
        return <ProgramItem key={i} program={item} />
      })}
  </ul>
)
// ______________________________________________________
//
const spStyle = `
  > li {
    &::before {
      display: none;
    }
    &:last-child {
      border-bottom: 1px solid rgba(216, 216, 216, 0.8);
    }
  }
`
const StyledComponent = styled(Component)`
  > li {
    position: relative;
    &:not(:first-child) {
      &::before {
        content: "";
        position: absolute;
        width: 2px;
        height: 32px;
        left: 52px;
        top: 0px;
        background: #c4c4c4;
        transform: translateY(-50%);
      }
    }
  }
  ${media.lessThan("medium")`${spStyle}`}
`
// ______________________________________________________
//
const Container: React.FC<ContainerProps> = props => {
  const items = [...props.programs, ...props.sessions]
  return <StyledComponent className={props.className} items={items} />
}
export default Container
