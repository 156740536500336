import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import media from "styled-media-query"
import SocialLinks from "@components/common/socialLinks"
import { PagesTopQuery } from "@/types"
// ______________________________________________________
//
export type Speaker = PagesTopQuery["allContentfulSpeaker"]["edges"][0]
export type Props = {
  className?: string
} & Speaker
// ______________________________________________________
//
const Component: React.FC<Props> = props => {
  const { node } = props
  const { name, community, githubAccount, twitterAccount, avatar } = node
  return (
    <div className={props.className}>
      {/* TODO: Speaker detailが公開されたら順次
      <Link to={`/2020/sessions/`}> */}
      <div className="Avatar">
        <img
          // @ts-ignore
          loading="lazy"
          src={avatar?.file?.url ?? ""}
        />
      </div>
      <h3 className="Name">{name}</h3>
      {community && <p className="Company">{community}</p>}
      {/*
      </Link>
       */}
      <SocialLinks
        githubAccount={githubAccount}
        twitterAccount={twitterAccount}
      />
    </div>
  )
}
// ______________________________________________________
//
const spStyle = `
  padding: 20px 20px 0;
  a {
    &:hover {
      opacity: 1;
    }
  }
  .Avatar {
    margin-bottom: 12px;
  }
  .Name {
    font-size: 16px;
    line-height: 1;
  }
`
const StyledComponent = styled(Component)`
  padding: 20px 20px 4px;
  box-sizing: border-box;
  a {
    text-decoration: none;
    transition: opacity 0.3s ease-out;
    &:hover {
      opacity: 0.7;
    }
  }
  .Avatar {
    position: relative;
    margin-bottom: 16px;
    border-radius: 50%;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
  .Name {
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.ivory};
    font-size: 20px;
    font-weight: normal;
    line-height: 1;
    text-align: center;
  }
  .Company {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.ivory};
    font-size: 12px;
    line-height: 1;
    opacity: 0.64;
    text-align: center;
  }
  ${media.lessThan("medium")`${spStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
