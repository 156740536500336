import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import SectionTitle from "@components/common/sectionTitle"
import List from "./list"
import { Speaker } from "./list/item"
import { colors } from "@components/layouts"
// ______________________________________________________
//
type Props = {
  className?: string
  speakers: Speaker[]
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <section className={props.className} id="speakers">
    <div className="Inner">
      <SectionTitle title="Speakers" color={colors.ivory} />
      <List speakers={props.speakers} />
    </div>
  </section>
)
// ______________________________________________________
//
const spStyle = `
  .Inner {
    padding: 48px 20px;
  }
`
const StyledComponent = styled(Component)`
  .Inner {
    max-width: ${1024 + 80}px;
    margin: 0 auto;
    padding: 104px 40px 112px;
    box-sizing: border-box;
  }
  ${media.lessThan("medium")`${spStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
