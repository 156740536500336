import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import ImgTwitter from "@/src/images/icon/ic-twitter.svg"
import ImgGithub from "@/src/images/icon/ic-github.svg"
// ______________________________________________________
//
export type Props = {
  className?: string
  twitterAccount?: null | string
  githubAccount?: null | string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    {props.twitterAccount && (
      <a href={`https://twitter.com/${props.twitterAccount}`}>
        <ImgTwitter />
      </a>
    )}
    {props.githubAccount && (
      <a href={`https://github.com/${props.githubAccount}`}>
        <ImgGithub />
      </a>
    )}
  </div>
)
// ______________________________________________________
//
const pcStyle = `
`
const spStyle = `
`
const StyledComponent = styled(Component)`
  display: flex;
  justify-content: center;
  a {
    width: 20px;
    height: 20px;
    margin: 0 6px;
    transition: opacity 0.3s ease-out;
    &:hover {
      opacity: 0.7;
    }
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
