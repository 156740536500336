import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Icon, { IconName } from "./icon"
import { PagesTopQuery } from "@/types"
// ______________________________________________________
//
export type Program = PagesTopQuery["allContentfulProgram"]["edges"][0]
type Props = {
  className?: string
  program: Program & { node: { durationMinutes: number } }
}
// ______________________________________________________
//
const Component: React.FC<Props> = ({ className, program }) => {
  const { node } = program
  const { name, iconName, startsAt, endsAt, durationMinutes } = node
  return (
    <li className={className}>
      <div className="TimeBlock">
        <p className="Time">{startsAt}</p>
        <span className="TimeMinute">{durationMinutes}min</span>
      </div>
      <div className="Detail">
        <figure className="Icon">
          <Icon iconName={iconName as IconName} />
        </figure>
        <div className="DetailInner">
          <h3 className="Title">{name}</h3>
        </div>
      </div>
    </li>
  )
}
// ______________________________________________________
//
const spStyle = `
  .TimeBlock {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 103px;
    padding: 8px 12px;
    border-radius: 0px 0px 16px 0px;
    background: rgba(216, 216, 216, 0.8);
    box-sizing: border-box;
  }
  .Time {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1;
  }
  .TimeMinute {
    padding-left: 3px;
    font-size: 12px;
    line-height: 1;
  }
  .Detail {
    height: auto;
    padding: 40px 32px 23px 20px;
    border-top: 1px solid rgba(216, 216, 216, 0.8);
  }
  .Icon {
    margin-right: 16px;
  }
  .DetailInner {
    padding: 0;
    border-bottom: 0;
  }
  .Title {
    font-size: 16px;
    line-height: 1.2;
  }
  .NameContiner {
    margin-top: 2px;
  }
  .Name {
    font-size: 12px;
  }
`
const StyledComponent = styled(Component)`
  display: flex;
  align-items: center;
  text-decoration: none;
  .TimeBlock {
    padding: 0 22px;
    text-align: center;
  }
  .Time {
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.black};
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
    text-align: center;
  }
  .TimeMinute {
    color: ${({ theme }) => theme.colors.black};
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 1;
    opacity: 0.72;
  }
  .Detail {
    display: flex;
    align-items: center;
    flex: 1;
    height: 108px;
  }
  .Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    margin-right: 24px;
    overflow: hidden;
    border: 1px solid rgba(40, 44, 52, 0.08);
    border-radius: 50%;
    svg {
      width: 65%;
      height: 65%;
    }
  }
  .DetailInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 16px 0 20px;
    flex: 1;
    border-bottom: 1px solid rgba(216, 216, 216, 0.8);
    box-sizing: border-box;
  }
  .Title {
    color: ${({ theme }) => theme.colors.black};
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
  }
  .NameContiner {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.black};
  }
  ${media.lessThan("medium")`${spStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
