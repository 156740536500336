import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import SectionTitle from "@components/common/sectionTitle"
import Button from "@components/common/button"
import { PagesTopQuery } from "@/types"
import ImgNaviTime from "@/src/images/navitime.jpg"
// ______________________________________________________
//
export type Paragraph = PagesTopQuery["allContentfulParagraph"]["edges"][0]
type Props = {
  className?: string
  paragraph?: Paragraph
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <section className={props.className} id="venue">
    <div className="Inner">
      <SectionTitle title="Venue" />
      <div className="Unit">
        <div className="Detail">
          <h3 className="Title">NAVITIME JAPAN</h3>
          <p className="Text">
            〒107-0062 東京都港区南青山3丁目8−38 南青山東急ビル
          </p>
          <p className="Text">
            東京メトロ銀座線・半蔵門線・千代田線「表参道」駅A4出口
            徒歩4分東京メトロ銀座線「外苑前」駅1a出口 徒歩5分
          </p>
          <Button
            className="Button"
            label="GOOGLE MAP"
            href="https://goo.gl/maps/z9Kmy82UopBxcP318"
            target={true}
            type="external"
          />
        </div>

        <figure className="Image">
          <img src={ImgNaviTime} alt="" />
        </figure>
      </div>
    </div>
  </section>
)
// ______________________________________________________
//
const spStyle = `
  .Inner {
    padding: 48px 0;
    text-align: center;
  }
  .Button {
    margin: 32px 0 40px;
  }
  .Unit {
    display: block;
    padding: 0;
  }
  .Image {
    width: 100%;
    margin-left: 0;
    img {
      display: block;
      width: 100%;
    }
  }
  .Detail {
    flex: 1;
  }
  .Title {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 1.2;
  }
  .Text {
    margin-bottom: 20px;
    padding: 0 16px;
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
  }
`
const StyledComponent = styled(Component)`
  background-color: ${({ theme }) => theme.colors.ivory};
  .Inner {
    max-width: ${1024 + 80}px;
    margin: 0 auto;
    padding: 104px 40px 120px;
    box-sizing: border-box;
  }
  .Button {
    margin-top: 36px;
    background: #d33736;
    color: ${({ theme }) => theme.colors.ivory};
    .Icon svg path {
      fill: ${({ theme }) => theme.colors.ivory};
    }
  }
  .Unit {
    display: flex;
    padding: 0 20px;
  }
  .Image {
    width: 467px;
    margin-left: 44px;
    img {
      display: block;
      width: 100%;
    }
  }
  .Detail {
    flex: 1;
  }
  .Title {
    margin-bottom: 32px;
    color: ${({ theme }) => theme.colors.black};
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: 0.01em;
  }
  .Text {
    margin-bottom: 24px;
    color: ${({ theme }) => theme.colors.black};
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.02em;
  }
  ${media.lessThan("medium")`${spStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
