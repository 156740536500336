import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import SectionTitle from "@components/common/sectionTitle"
import { colors } from "@components/layouts"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <section className={props.className} id="venue">
    <div className="Inner">
      <SectionTitle title="FAQ" color={colors.ivory} />
      <dl className="List">
        <div className="Block">
          <dt className="Title">チケットの購入は先着順ですか？抽選ですか？</dt>
          <dd className="Description">チケットの購入は先着順になります。</dd>
        </div>

        <div className="Block">
          <dt className="Title">領収書について</dt>
          <dd className="Description">
            チケット購入後に届くメールに記載のある「領収データ」リンクからアクセスしてください。
            詳しくはこちらをご覧ください。
            <a
              href=" https://help.peatix.com/customer/ja/portal/articles/221024"
              target="_blank"
            >
              Peatix Help | 領収書について
            </a>
          </dd>
        </div>

        <div className="Block">
          <dt className="Title">チケットのキャンセル、返金について</dt>
          <dd className="Description">
            お支払い後のキャンセルは受け付けることができません。あらかじめご了承ください。
            <br />
            イベント側都合のイベントの中止の場合は払い戻します。
            この際、チケット費用のみの返金となり、旅費やホテル代については補償対象外となります。
          </dd>
        </div>

        <div className="Block">
          <dt className="Title">学割チケットに必要なもの</dt>
          <dd className="Description">
            当日受付にて学生証の提示をお願いします。提示がない場合は入場をお断りする可能性がございます。
          </dd>
        </div>

        <div className="Block">
          <dt className="Title">
            チケットを購入しましたが、メールが届きません
          </dt>
          <dd className="Description">
            チケット購入後に「お申込み詳細」メールが届きます。もし、届かない場合は
            peatix上で「マイチケット」を合わせてご確認ください。
            <br />
            <br />
            詳細はこちらをご覧ください。
            <a
              href="https://help.peatix.com/customer/portal/articles/1819396-%E3%83%81%E3%82%B1%E3%83%83%E3%83%88%E7%94%B3%E8%BE%BC%E3%81%BF%E5%BE%8C%E3%81%AB%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%AF%E5%B1%8A%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F"
              target="_blank"
            >
              Peatix Help | チケット申込み後にメールは届きますか？
            </a>
          </dd>
        </div>

        <div className="Block">
          <dt className="Title">
            セッションのライブ配信や後日の撮影された内容の公開はありますか？
          </dt>
          <dd className="Description">
            ライブ配信や後日の映像公開は予定しておりません。
          </dd>
        </div>
      </dl>
    </div>
  </section>
)
// ______________________________________________________
//
const spStyle = `
  .Inner {
    padding: 48px 20px;
  }
  .Title {
    margin-bottom: 16px;
    font-size: 18px;
  }
  .Description {
    font-size: 14px;
    line-height: 1.8;
  }
`
const StyledComponent = styled(Component)`
  .Inner {
    max-width: ${1024 + 80}px;
    margin: 0 auto;
    padding: 104px 40px 146px;
    box-sizing: border-box;
  }
  .Block {
    padding: 32px 0;
  }
  .Title {
    margin-bottom: 24px;
    color: ${({ theme }) => theme.colors.ivory};
    font-weight: bold;
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: 0.01em;
  }
  .Description {
    color: ${({ theme }) => theme.colors.ivory};
    font-size: 16px;
    line-height: 1.8;
    span {
      font-weight: bold;
    }
    a {
      color: ${({ theme }) => theme.colors.ivory};
      text-decoration: underline;
    }
  }
  ${media.lessThan("medium")`
  ${spStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
